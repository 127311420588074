import { type ConstructorType } from '../types/utils'
import { createModelKey } from '../utils/serialization'
import { errorLog } from '../utils/logging'

type ApiModelStructure<TAttributes, TEmbeds> = TAttributes & { [EMBED_KEY]?: TEmbeds }
export type ApiModelKey = string
export type ApiModelReducedData<TAttributes, TEmbeds> = [ApiModelStructure<TAttributes, TEmbeds>, ApiModelKey | null]

// @ts-expect-error accessing private property
export type ApiModelAttributes<T extends ApiModel> = T['____a']
// @ts-expect-error accessing private property
export type ApiModelEmbeds<T extends ApiModel> = T['____e']
export type ApiModelEmbedValue<T extends ApiModel, K extends keyof ApiModelEmbeds<T>> = ApiModelEmbeds<T>[K]

// @ts-expect-error accessing private property
export type ApiModelFilter<T extends ApiModel> = T['____f']


/**
 * The key used to access the embeds property of a data model.
 */
const EMBED_KEY = '_embedded' as const

export class ApiModel<
    TAttributes extends Record<string, any> = {},
    TEmbeds extends Record<string, any> = {},
    TFilters extends Record<string, any> = {},
> {
    private declare ____a: TAttributes
    private declare ____e: TEmbeds
    private declare ____f: TFilters

    private readonly __d: TAttributes & { [EMBED_KEY]?: TEmbeds } | null = {} as any
    // instantiated models for attributes with type `ApiModel`
    private __m: { a: Partial<Record<keyof TAttributes, ApiModel>>, e: Partial<Record<keyof TEmbeds, ApiModel>> } =
        { a: {}, e: {} }


    constructor(data: Partial<TAttributes> & { [EMBED_KEY]?: Partial<TEmbeds> }) {
        this.__d = data as any
    }

    /**
     * Get the attribute of the model.
     * If the attribute is not present, `null` is returned.
     * Automatically instantiates the model if the attribute is a model. The model is then saved and returned.
     * (So that the same instance is returned every time. - Except for when the attribute of the model is edited during
     * its lifetime. In that case, the model is destructed when the attribute is set and a new one is created when the
     * attribute is accessed again.)
     * @param attr the key of the attribute to get
     * @protected
     */
    protected _getAttribute<K extends keyof TAttributes>(attr: K): TAttributes[K] | null
    protected _getAttribute<K extends keyof TAttributes, M extends ConstructorType<ApiModel>>(attr: K, model: M): TAttributes[K] extends any[] ? InstanceType<M>[] : InstanceType<M> | null
    protected _getAttribute<K extends keyof TAttributes, M extends ConstructorType<ApiModel>>(attr: K, model?: M): TAttributes[K] | (TAttributes[K] extends any[] ? InstanceType<M>[] : InstanceType<M> | null) | null {
        if (model) {
            // if the attribute isn't present, return null
            if (typeof this.__d?.[attr] === 'undefined' || this.__d[attr] === null) return null as any
            // return the already instantiated model or instantiate it, save it and return it
            return (
                this.__m.a[attr] ??
                (
                    this.__m.a[attr] = Array.isArray(this.__d[attr])
                        ? this.__d[attr].map((item: any) => new model(item))
                        : new model(this.__d[attr])
                )
            )
        }

        // return the bare attribute or null if it's not present
        return this.__d?.[attr] ?? null
    }

    /**
     * Get the embed of the model.
     * If the embed is not present, `null` is returned.
     * Automatically instantiates the model if the embed is a model. The model is then saved and returned.
     * (So that the same instance is returned every time.)
     * @param embed the key of the embed to get
     * @protected
     */
    protected _getEmbed<K extends keyof TEmbeds>(embed: K): TEmbeds[K] | null
    protected _getEmbed<K extends keyof TEmbeds, M extends ConstructorType<ApiModel>>(embed: K, model: M, options?: Partial<GetEmbedOptions>):
    TEmbeds[K] extends any[]
        ? InstanceType<M>[]
        : TEmbeds[K] extends Record<any, InstanceType<M>>
            ? Record<keyof TEmbeds[K], InstanceType<M>>
            : InstanceType<M> | null
    protected _getEmbed<K extends keyof TEmbeds, M extends ConstructorType<ApiModel>>(embed: K, model?: M, options?: Partial<GetEmbedOptions>):
        TEmbeds[K]
        | (
            TEmbeds[K] extends any[]
                ? InstanceType<M>[]
                : TEmbeds[K] extends Record<any, InstanceType<M>>
                    ? Record<keyof TEmbeds[K], InstanceType<M>>
                    : InstanceType<M> | null
        )
        | null
    {
        const embedData = this.__d?.[EMBED_KEY]

        if (import.meta.dev) {
            if (!embedData) {
                errorLog(`[${this.constructor.name}]: Embeds are not present in the model. This should never happen.`, this)
            }

            if (embedData?.[embed] === 'undefined') {
                console.error(`[${this.constructor.name}]: Embed '${embed as string}' is not present.`, this)
            }
        }

        if (!embedData) return null

        if (model) {
            // if the embed isn't present, return null
            if (typeof embedData[embed] === 'undefined' || embedData[embed] === null) return null
            // return the already instantiated model or instantiate it, save it and return it
            return (
                this.__m.e[embed] ??
                (
                    this.__m.e[embed] = Array.isArray(embedData[embed])
                        // handle arrays of models
                        ? embedData[embed].map((item: any) => new model(item))
                        // handle objects of models
                        : options?.isModelMap && typeof embedData[embed] === 'object' && embedData[embed] !== null && Object.getOwnPropertySymbols(embedData[embed]).length > 0
                            ? Object.fromEntries(Object.entries(embedData[embed]).map(([key, value]) => [key, new model(value)]))
                            // handle single models
                            : new model(embedData[embed])
                )
            )
        }

        // return the bare embed or null if it's not present
        return embedData[embed] ?? null
    }


    /**
     * Set the attribute of the model.
     * Overwrites the attribute if it's already present.
     *
     * Only to be used for special cases when absolutely necessary.
     * @param attr the key of the attribute to set
     * @param value the value to set
     * @protected
     */
    protected _setAttribute<K extends keyof TAttributes>(attr: K, value: TAttributes[K]): void {
        if (!this.__d) return
        this.__d[attr] = value
        // check for a model instance for the current attribute and remove it if it exists so that a new one
        // is created next time it's accessed
        if (this.__m.a[attr]) delete this.__m.a[attr]
    }

    /**
     * Set the embed of the model.
     * Overwrites the embed if it's already present.
     *
     * Only to be used for special cases when absolutely necessary.
     * @param embed the key of the embed to set
     * @param value the value to set
     * @protected
     */
    protected _setEmbed<K extends keyof TEmbeds>(embed: K, value: TEmbeds[K]): void {
        if (!this.__d) return
        this.__d[EMBED_KEY] = this.__d[EMBED_KEY] ?? {} as any
        this.__d[EMBED_KEY]![embed] = value
        // check for a model instance for the current embed and remove it if it exists so that a new one
        // is created next time it's accessed
        if (this.__m.e[embed]) delete this.__m.e[embed]
    }

    // --------------------------------------------------------------
    // SERIALIZATION

    _toJSON(): ApiModelStructure<TAttributes, TEmbeds> {
        return this.__d as any
    }

    private getReducedData(): ApiModelReducedData<TAttributes, TEmbeds> {
        return [this._toJSON(), createModelKey(this.constructor as any)]
    }
}

interface GetEmbedOptions {
    isModelMap: boolean
}
